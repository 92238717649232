import { useEffect, useState, lazy, Suspense } from 'react';

function SimulatorLoader() {
  const [DynamicComponent, setDynamicComponent] = useState(null);
  const [simulationName, setSimulationName] = useState('');
  const [simulationNameError, setSimulationNameError] = useState('');
  
  const uri = new URL(window.location.href);
  const theme = uri.searchParams.get('theme');
  const boardType = uri.searchParams.get('boardType');
  const mode=uri.searchParams.get('mode')
  const hmiResolution = JSON.parse(uri.searchParams.get('hmiResolution'));
  let simulationPath = uri.searchParams.get('simulationPath');
  if (!simulationPath) {
    simulationPath = '';
  }
  let bitDepth = uri.searchParams.get('bitDepth');
  if (!bitDepth) {
    bitDepth = 1;
  }

  if (uri.searchParams.get('simulationName') && uri.searchParams.get('simulationName') !== simulationName)
    setSimulationName(uri.searchParams.get('simulationName'));

  useEffect(() => {
    if (simulationName) {
      const Simulator = lazy(() =>
        import('../../simulators/' + simulationName + '/src/lib').catch((err) =>
          setSimulationNameError(err)
        )
      );
      setDynamicComponent(Simulator);
    }
  }, [simulationName]);

  document.body.style.background = theme === 'light' ? '#f4f4f5' : '#2d2c32';
  document.body.style.display = "flex";
  document.body.style.justifyContent = 'center';
  document.body.style.alignItems='center';

  return (
    
    <div>
      
      <Suspense fallback={<div></div>}>
        {DynamicComponent &&
          (simulationNameError ? (
            <div>simulationName parameter is not valid</div>
          ) : (
            <DynamicComponent 
              theme={theme}
              hmiResolution={hmiResolution}
              boardType={boardType}
              simulationPath={simulationPath}
              bitDepth={bitDepth}
              mode={mode}
            />
          ))}
      </Suspense>
    </div>
  );
}

export default SimulatorLoader;
